import React, { useState, useEffect, useRef } from "react";
import { Row, Button, Col, Checkbox, Card, Input, Space, Typography } from "antd";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import { CloseOutlined, LoadingOutlined, StopOutlined, SearchOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import defaultImage from "../../../assets/images/no-image.svg";
import Back from "../../../assets/images/backIcon.svg";
import data from "../../../constants/restaurantPos.json";
import removeBg from "../../../assets/images/removebg.png";
import PlusIcon from "../../../assets/images/plusIconRestaurant.svg";
import order from "../../../assets/images/order.svg";
import MenuLayout from "./MenuLayout";
import "./restaurantStyle.css";
import moment from "moment";
import db from "../../../database";

const HomeDeliveryComponent = ({
  setKeyValue,
  deleteCart,
  cart,
  userPreferences,
  categorieFlag,
  setCategorieFlag,
  addDefinedProductWithAddons,
  restaurantProductCategory,
  productsData,
  pickProduct,
  increaseProductQty,
  decreaseProductQty,
  deleteProduct,
  setDisplayCustomerSearch,
  handleVoid,
  setPaymentVisible,
  showProducts,
  setShowProducts,
}) => {
  const { Text, Title } = Typography;
  const [takeAwayOrdes, setTakeAwayOrders] = useState([]);
  const [expandedOrders, setExpandedOrders] = useState({});
  // const [showProducts, setShowProducts] = useState(false);
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  useEffect(() => {
    db.orders
      .filter((order) => order.typeOrder === "Y")
      .toArray()
      .then((filteredOrder) => {
        setTakeAwayOrders(filteredOrder);
      })
      .catch((error) => {
        console.error("Failed to filter products:", error);
      });
  }, [cart]);

  const toggleExpand = (orderId) => {
    setExpandedOrders((prevState) => ({
      ...prevState,
      [orderId]: !prevState[orderId],
    }));
  };

  return (
    <>
      {showProducts ? (
        <MenuLayout
          {...{ setKeyValue, deleteCart, cart, userPreferences }}
          categorieFlag={categorieFlag}
          setCategorieFlag={setCategorieFlag}
          addDefinedProductWithAddons={addDefinedProductWithAddons}
          restaurantProductCategory={restaurantProductCategory}
          productsData={productsData}
          pickProduct={pickProduct}
          increaseProductQty={increaseProductQty}
          decreaseProductQty={decreaseProductQty}
          deleteProduct={deleteProduct}
          setDisplayCustomerSearch={setDisplayCustomerSearch}
          handleVoid={handleVoid}
          setPaymentVisible={setPaymentVisible}
          showProducts={showProducts}
          setShowProducts={setShowProducts}
        />
      ) : (
        <div style={{ width: "100%" }}>
          <div style={{ position: "relative", padding: "2vh 1.3vw", fontSize: "1vw" }}>
            <Row>
              <Col span={10} style={{ fontSize: "1.25em", fontWeight: "600", color: "#0F0718" }}>
                Home Delivery
              </Col>
            </Row>
            <Scrollbars autoHide style={{ height: "90vh" }}>
              <InfiniteScroll
                dataLength={takeAwayOrdes.length}
                hasMore={true}
                endMessage={
                  <p className="center">
                    <b>Yay! you have seen it all</b>
                  </p>
                }
                style={{ overflowX: "hidden" }}
              >
                <div
                  style={{
                    marginTop: "3vh",
                    backgroundColor: "#fff",
                    height: "87vh",
                    borderRadius: "15px",
                    padding: "1vh 0.5vw",
                  }}
                >
                  <Row>
                    {takeAwayOrdes.map((orderData) => {
                      const isExpanded = expandedOrders[orderData?.documentno] || false;
                      return (
                        <Col span={8} style={{ padding: "1vh 0.6vw" }} key={orderData?.documentno}>
                          <Card
                            style={{
                              backgroundColor: "#F3F4F9",
                              padding: "1vh 0.7vw",
                              borderRadius: "10px",
                              height: "18vh",
                              fontSize: "1vw",
                              cursor: "pointer",
                              borderColor: "#F3F4F9",
                              overflow: "auto",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                maxHeight: isExpanded ? "12vh" : "8vh",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1.1em",
                                  fontWeight: 600,
                                  color: "#0F0718",
                                  fontFamily: "Inter",
                                }}
                              >
                                Order {orderData?.documentno || null}
                              </span>
                              <span
                                style={{
                                  fontSize: "1em",
                                  fontWeight: 600,
                                  color: "#0F0718",
                                  fontFamily: "Inter",
                                }}
                              >
                                Order {orderData?.customer?.name || null}
                              </span>

                              {orderData?.items?.slice(0, isExpanded ? orderData?.items?.length : 1).map((item, index) => (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "0.8em",
                                    fontWeight: 500,
                                    color: "#929098",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  {item.name || null} X {item.weight}
                                </span>
                              ))}

                              {orderData?.items?.length > 1 && (
                                <button
                                  onClick={() => toggleExpand(orderData?.documentno)}
                                  style={{
                                    fontSize: "0.8em",
                                    color: "#A4CD38",
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                    textAlign: "left",
                                    padding: 0,
                                  }}
                                >
                                  {isExpanded ? "Show less" : `+ ${orderData?.items?.length - 1} more items`}
                                </button>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  fontSize: "1em",
                                  fontWeight: 600,
                                  color: "#0F0718",
                                  fontFamily: "Inter",
                                }}
                              >
                                <span>
                                  <img src={order} height="13" style={{ verticalAlign: "middle" }} />
                                  Order placed today at {orderData?.orderTime?.split(" ")[1]}
                                </span>
                                <span
                                  style={{
                                    fontSize: "1.15em",
                                    fontWeight: 600,
                                    color: "#0F0718",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  Amount: {orderData?.totalAmount || orderData?.total || 0}
                                </span>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </InfiniteScroll>
            </Scrollbars>
          </div>

          <Button
            onClick={() => {
              deleteCart();
              // setKeyValue("2");
              localStorage.setItem("dineIn", "N");
              localStorage.setItem("homeDelivery", "Y");
              setShowProducts(true);
            }}
            style={{
              position: "absolute",
              width: "8vw",
              height: "6.5vh",
              backgroundColor: "#2F3856",
              color: "#fff",
              borderRadius: "10px",
              bottom: "4vh",
              right: "2.5vw",
            }}
          >
            New Order
          </Button>
        </div>
      )}
    </>
  );
};

export default HomeDeliveryComponent;
