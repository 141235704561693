import React, { useState, useEffect, useRef } from "react";
import { Row, Button, Col, Checkbox, Card, Input, Space, Typography, Drawer, Form } from "antd";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import RetailModal from "../Retail/RetailModals";
import db from "../../../database";
import Calender from "../../../assets/images/calender.svg";
import Close from "../../../assets/images/close-x.svg";
import CancelArrow from "../../../assets/images/cancelArrow.svg";

const CashManagement = (props) => {
  const { Text, Title } = Typography;
  const {
    setAddCashFlag,
    setCashAddInFlag,
    handleCahInOut,
    cashAddInFlag,
    addCashFlag,
    posConfig,
    onChangeCheckbox,
    width,
    cashManagementForm,
    cashIn,
    pettCashIn,
    userPreferences,
  } = props;
  const currentDateTime = useState(moment(new Date()).format(userPreferences.dateFormat));
  const [cashInData, setCashInData] = useState([]);

  const handleCashManagement = (value) => {
    let formData = cashManagementForm.getFieldsValue(true);

    if (formData.amount === "" && value === "x") {
      cashManagementForm.setFieldsValue({
        amount: "",
      });
    } else if (value === "x") {
      cashManagementForm.setFieldsValue({
        amount: `${formData.amount.toString().substring(0, formData.amount.toString().length - 1)}`,
      });
    } else {
      cashManagementForm.setFieldsValue({
        amount: `${formData.amount ? formData.amount : ""}${value}`,
      });
    }
  };

  const handleCashInNote = (value) => {
    cashManagementForm.setFieldsValue({
      note: value,
    });
  };

  useEffect(() => {
    db.cashInCashOut.toArray().then((res) => {
      setCashInData(res);
    });
  }, [cashInData]);
  const handleClear = () => {
    cashManagementForm.setFieldsValue({ amount: "" });
  };

  return (
    <div style={{ padding: "2vh 1.5vw", position: "relative", height: "100vh" }}>
      {/* <RetailModal {...props} /> */}
      <Row>
        <Col span={6} offset={18} style={{ textAlign: "right", fontSize: "1vw" }}>
          <img src={Calender} style={{ height: "3.5vh", paddingRight: "0.5vw", paddingBottom: "0.3em" }} />
          <span style={{ fontSize: "1.25em", color: "#929098", fontWeight: "500" }}>{currentDateTime}</span>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ paddingTop: "4vh" }}>
        {cashInData.map((recordData) => {
          return (
            <Col span={8}>
              <Card style={{ height: "14vh", width: "100%", borderRadius: "10px", padding: "2vh 1.3vw", fontSize: "1vw" }}>
                <Space style={{ width: "100%", justifyContent: "space-between" }}>
                  <Text style={{ fontSize: "1.25em", fontWeight: "600", color: "#0F0718" }}>{recordData.note}</Text>
                  <Text style={{ fontSize: "1.25em", fontWeight: "600", color: "#0F0718" }}>{recordData.type}</Text>
                </Space>
                <Space style={{ width: "100%", justifyContent: "space-between", paddingTop: "2vh" }}>
                  <Text style={{ fontSize: "1em", fontWeight: "500", color: "#0F0718" }}>{recordData.date}</Text>
                  <Text style={{ fontSize: "1.25em", fontWeight: "600", color: "#0F0718" }}>{recordData.amount}</Text>
                </Space>
              </Card>
            </Col>
          );
        })}
      </Row>
      {/* <div>
        <Space direction="horizontal" style={{ position: "absolute", right: "1.3vw", bottom: "2vh", fontSize: "1vw" }}>
          <Button
            onClick={() => {
              setAddCashFlag(true);
              setCashAddInFlag(true);
            }}
            style={{ height: "6vh", backgroundColor: "#2F3856", color: "#fff", width: "11vw", borderRadius: "10px", fontSize: "1em", fontWeight: "500" }}
          >
            Remove Cash
          </Button>
          <Button
            onClick={() => {
              setAddCashFlag(true);
              setCashAddInFlag(false);
            }}
            style={{ height: "6vh", backgroundColor: "#2F3856", color: "#fff", width: "11vw", borderRadius: "10px", fontSize: "1em", fontWeight: "500" }}
          >
            Add Cash
          </Button>
        </Space>
      </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: cashInData.length <= 0 ? "center" : "flex-end",
          alignItems: cashInData.length <= 0 ? "center" : "flex-end",
          position: cashInData.length <= 0 ? "relative" : "relative",
          right: cashInData.length <= 0 ? "1.3vw" : "0",
          bottom: cashInData.length <= 0 ? "2vh" : "0",
          width: "100%",
          marginTop: cashInData.length <= 0 ? "15vh" : "65vh",
          textAlign: cashInData.length <= 0 ? "right" : "center",
        }}
      >
        {cashInData.length <= 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <img
              src="https://s3-alpha-sig.figma.com/img/3e24/19f8/7921a4f07bd3b4fa98caadfb0d25205a?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=k5EALDNp1qGQFUecNEXI7RqOATbJK0oIZkYOhI3XRcP4w4uqAhoWHGEW935NUikeKHC~NmAkBDlnrC5f5Cb5zfZ5~OLmdXakIOBvUL-gWTf38UQMcEBrS7i~zuYrp2RIG5-OVyp6cOXELI-GEkfWvZGdbgch6sdfHyU1V5SfrnqGoEU-pUPLYauJelvaoq8hOt4Ekjy54c6pPGSU0IJTDR3Phy10v3wTYzW6XPMyyqMmk17VHo4fXlDy3f3urPFcsMXj4h2CisbmQyxOrNz0D4lXy787Vy5QIr9TNIF6l9xMZyVA4rw3PPhsN8kvVm86EKpA9ytmSq~j0dvgGVd-iw__"
              alt="Cash Drawer Animation"
              style={{
                width: "150px",
                height: "auto",
                marginBottom: "2vh",
              }}
            />
            <div
              style={{
                marginBottom: "3vh",
                fontSize: "1vw",
                fontWeight: "600",
                color: "#0F0718",
                textAlign: "center",
              }}
            >
              Add or Remove Cash from the Cash Drawer
            </div>
          </div>
        )}

        <Space direction="horizontal" style={{ fontSize: "1vw" }}>
          <Button
            onClick={() => {
              setAddCashFlag(true);
              setCashAddInFlag(true);
            }}
            style={{
              height: "6vh",
              backgroundColor: "#2F3856",
              color: "#fff",
              width: "11vw",
              borderRadius: "10px",
              fontSize: "1em",
              fontWeight: "500",
            }}
          >
            Remove Cash
          </Button>
          <Button
            onClick={() => {
              setAddCashFlag(true);
              setCashAddInFlag(false);
            }}
            style={{
              height: "6vh",
              backgroundColor: "#2F3856",
              color: "#fff",
              width: "11vw",
              borderRadius: "10px",
              fontSize: "1em",
              fontWeight: "500",
            }}
          >
            Add Cash
          </Button>
        </Space>
      </div>
      <Drawer
        closable={true}
        placement="bottom"
        visible={addCashFlag}
        className="filter"
        bodyStyle={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#fff" }}
        // onClose={() => {
        //   cashManagementForm.resetFields();
        //   setAddCashFlag(false);
        // }}
        //  id="sm-drawer-close"
        height="80%"
        headerStyle={{ display: "none" }}
      >
        <Form form={cashManagementForm} onFinish={handleCahInOut} layout="vertical" style={{ fontSize: "1vw" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "2vh",
              fontSize: "1vw",
              marginTop: "6vh",
            }}
          >
            <p
              style={{
                fontSize: "1.5em",
                marginTop: "3vh",
                fontWeight: "500",
                color: "#0F0718",
                flex: 1,
                textAlign: "center",
              }}
            >
              {cashAddInFlag ? "Remove Cash" : "Add Cash"}
            </p>
            <img
              src={Close}
              onClick={() => {
                cashManagementForm.resetFields();
                setAddCashFlag(false);
              }}
              style={{
                marginLeft: "auto",
                paddingBottom: "4vh",
                paddingTop: "2vh",
                cursor: "pointer",
                width: "1.5vw",
              }}
            />
          </div>
          <Row style={{ padding: "3% 0 0% 0" }}>
            <Col span={1} />
            <Col span={11} style={{ paddingLeft: "1vw" }}>
              {cashAddInFlag ? (
                <Row>
                  <Col span={2} />
                  <Col span={10}>
                    <Form.Item name={"cashOut"} style={{ marginLeft: "8vw", fontSize: "1vw" }}>
                      {posConfig.cashOut ? (
                        <Checkbox
                          style={{
                            fontWeight: 500,
                            color: "#0F0718",
                            fontSize: "1em",
                            transform: "scale(1.2)",
                            lineHeight: "normal",
                          }}
                          checked={cashIn}
                          onChange={onChangeCheckbox}
                        >
                          Cash Out
                        </Checkbox>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    {posConfig.pettyCash === "Y" ? (
                      <Form.Item name={"pettyCashOut"} style={{ fontSize: "1vw" }}>
                        <Checkbox
                          checked={pettCashIn}
                          style={{
                            fontWeight: 500,
                            color: "#0F0718",
                            fontSize: "1em",
                            transform: "scale(1.2)",
                            lineHeight: "normal",
                          }}
                          onChange={onChangeCheckbox}
                        >
                          Petty cash out
                        </Checkbox>
                      </Form.Item>
                    ) : null}
                  </Col>
                  <Col span={2} />
                </Row>
              ) : (
                <Row>
                  <Col span={2} />
                  <Col span={10}>
                    <Form.Item name={"cashIn"} style={{ marginLeft: "8vw", fontSize: "1vw" }}>
                      {posConfig.cashIn ? (
                        <Checkbox
                          style={{
                            fontWeight: 500,
                            color: "#0F0718",
                            fontSize: "1em",
                            transform: "scale(1.2)",
                            lineHeight: "normal",
                          }}
                          checked={cashIn}
                          onChange={onChangeCheckbox}
                        >
                          Cash In
                        </Checkbox>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    {posConfig.pettyCash === "Y" ? (
                      <Form.Item name={"pettyCashIn"} style={{ fontSize: "1vw" }}>
                        <Checkbox
                          checked={pettCashIn}
                          style={{
                            fontWeight: 500,
                            color: "#0F0718",
                            fontSize: "1em",
                            transform: "scale(1.2)",
                            lineHeight: "normal",
                          }}
                          onChange={onChangeCheckbox}
                        >
                          Petty cash In
                        </Checkbox>
                      </Form.Item>
                    ) : null}
                  </Col>
                  <Col span={2} />
                </Row>
              )}
              <Row>
                <Col span={24}>
                  <Form.Item
                    className="popUpForm"
                    style={{
                      marginLeft: "7vw",
                      marginTop: "2vh",
                      fontSize: "1vw",
                    }}
                    name="amount"
                    label={
                      <div style={{ fontSize: "1vw" }}>
                        <span
                          style={{
                            color: "#0F0718",
                            fontSize: "1.25em",
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                          Amount{" "}
                        </span>
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: `Please enter amount`,
                      },
                    ]}
                  >
                    <Input
                      placeholder={`e.g, 100`}
                      size="large" // Handle input value changes
                      allowClear={false}
                      style={{
                        height: "7.5vh",
                        width: "95%",
                        borderRadius: "4px",
                        border: "1px solid rgba(146, 144, 152, 0.5)",
                        fontSize: "1.15em",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "0% 0 3% 0" }}>
                  <Form.Item
                    style={{
                      paddingBottom: 0,
                      marginLeft: "7vw",
                      marginTop: "1vh",
                      fontSize: "1vw",
                    }}
                    className="popUpForm"
                    name="note"
                    label={
                      <div style={{ fontSize: "1vw" }}>
                        <span
                          style={{
                            color: "#0F0718",
                            fontSize: "1.25em",
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                          Note{" "}
                        </span>
                      </div>
                    }
                  >
                    <Input.TextArea
                      placeholder="Type to add a note"
                      size="large"
                      onChange={(e) => {
                        handleCashInNote(e.target.value);
                      }}
                      allowClear={false}
                      style={{
                        height: "18vh",
                        width: "95%",
                        borderRadius: "4px",
                        border: "1px solid rgba(146, 144, 152, 0.5)",
                        fontSize: "1.15em",
                        resize: "none",
                      }}
                    />
                    <p
                      style={{
                        marginBottom: "0px",
                        marginTop: "5px",
                        fontWeight: "500",
                        fontSize: "0.8em",
                        color: "#929098",
                      }}
                    >
                      Max Characters : 200
                    </p>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={2} />
            <Col span={10} style={{ marginLeft: "2vw" }}>
              <Row gutter={[8, 8]}>
                <Col span={5}>
                  <button
                    className="amt-dial-btn-restaurant-cash-management"
                    onClick={(e) => {
                      handleCashManagement("1");
                      e.preventDefault();
                    }}
                  >
                    1
                  </button>
                </Col>
                <Col span={5}>
                  <button
                    className="amt-dial-btn-restaurant-cash-management"
                    onClick={(e) => {
                      handleCashManagement("2");
                      e.preventDefault();
                    }}
                  >
                    2
                  </button>
                </Col>
                <Col span={5}>
                  <button
                    className="amt-dial-btn-restaurant-cash-management"
                    onClick={(e) => {
                      handleCashManagement("3");
                      e.preventDefault();
                    }}
                  >
                    3
                  </button>
                </Col>
                <Col span={5}>
                  <button
                    className="amt-dial-btn-restaurant-cash-management-cancel-discount"
                    onClick={(e) => {
                      handleCashManagement("x");
                      e.preventDefault();
                    }}
                  >
                    <img src={CancelArrow} alt="back space" width={width <= 1024 ? 20 : 25} height={width <= 1024 ? 15 : 15} />
                  </button>
                </Col>
                <Col span={4} />
                <Col span={5}>
                  <button
                    className="amt-dial-btn-restaurant-cash-management"
                    onClick={(e) => {
                      handleCashManagement("4");
                      e.preventDefault();
                    }}
                  >
                    4
                  </button>
                </Col>
                <Col span={5}>
                  <button
                    className="amt-dial-btn-restaurant-cash-management"
                    onClick={(e) => {
                      handleCashManagement("5");
                      e.preventDefault();
                    }}
                  >
                    5
                  </button>
                </Col>
                <Col span={5}>
                  <button
                    className="amt-dial-btn-restaurant-cash-management"
                    onClick={(e) => {
                      handleCashManagement("6");
                      e.preventDefault();
                    }}
                  >
                    6
                  </button>
                </Col>
                <Col span={5}>
                  <button className="amt-dial-btn-restaurant-cash-management-cancel-discount" onClick={handleClear}>
                    Clear
                  </button>
                </Col>
                <Col span={4} />
                <Col span={15}>
                  <Row gutter={[8, 6]}>
                    <Col span={8}>
                      <button
                        className="amt-dial-btn-restaurant-cash-management"
                        onClick={(e) => {
                          handleCashManagement("7");
                          e.preventDefault();
                        }}
                      >
                        7
                      </button>
                    </Col>
                    <Col span={8}>
                      <button
                        className="amt-dial-btn-restaurant-cash-management"
                        onClick={(e) => {
                          handleCashManagement("8");
                          e.preventDefault();
                        }}
                      >
                        8
                      </button>
                    </Col>
                    <Col span={8}>
                      <button
                        className="amt-dial-btn-restaurant-cash-management"
                        onClick={(e) => {
                          handleCashManagement("9");
                          e.preventDefault();
                        }}
                      >
                        9
                      </button>
                    </Col>
                    <Col span={8}>
                      <button
                        className="amt-dial-btn-restaurant-cash-management"
                        onClick={(e) => {
                          handleCashManagement("0");
                          e.preventDefault();
                        }}
                      >
                        0
                      </button>
                    </Col>
                    <Col span={8}>
                      <button
                        className="amt-dial-btn-restaurant-cash-management"
                        onClick={(e) => {
                          handleCashManagement("00");
                          e.preventDefault();
                        }}
                      >
                        00
                      </button>
                    </Col>
                    <Col span={8}>
                      <button
                        className="amt-dial-btn-restaurant-cash-management"
                        onClick={(e) => {
                          handleCashManagement(".");
                          e.preventDefault();
                        }}
                      >
                        .
                      </button>
                    </Col>
                  </Row>
                </Col>
                <Col span={5} style={{ paddingRight: 0 }}>
                  <button
                    // id="sm-amount-buttonEnter"
                    className="amt-dial-btn-restaurant-cash-management-discount"
                  >
                    Enter
                  </button>
                </Col>
                <Col span={4} />
                {/* <Button size="large" style={data.posScreenStyles.productWeightModal.confirmQtyBtn} onClick={applyManualDiscount}>
                  <img src={Tick} alt="" />
                </Button> */}
              </Row>
              <Row style={{ marginTop: "3%" }}>
                <Button
                  htmlType="submit"
                  style={{
                    backgroundColor: "#2F3856",
                    color: "#fff",
                    width: "83.5%",
                    fontSize: "1.25em",
                    textAlign: "center",
                    fontWeight: "500",
                    borderRadius: "5px",
                    height: "7.5vh",
                  }}
                >
                  {/* Done */}
                  {cashAddInFlag ? "Remove Cash" : "Add Cash"}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};

export default CashManagement;
